import React from 'react'
import Layout from '../layouts/layout.js'
import Helmet from 'react-helmet'
import Pagetitle from '../components/Pagetitle'

const Contact = ({ data }) => (
  <Layout>
    <div>
      <Helmet title="Contact Us" />
      <Pagetitle title="Contact Us" />
      <p>Please contact us via the details below</p>
      <div>
        26 Wharf Street
        <br />
        Sowerby Bridge
        <br />
        West Yorkshire
        <br />
        HX6 2AE
        <br />
        <br />
        01422 831182
        <br />
    <br />
    email: <b><a href="mailto:theoffice@waywardmedia.co.uk">theoffice@waywardmedia.co.uk</a></b>

  <br />
      </div>
    </div>
  </Layout> 
)

export default Contact
